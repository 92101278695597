<template>
  <div class="container">
    <div class="back" v-show="isChichek">
      <span class="iconfont icon-jtback" @click="$router.go(-1)">{{$t('address.back_order')}}</span>
    </div>
    <h5>{{$t('address.my_address')}}</h5>
    <div class="addresslist">
      <div
        class="adItem"
        :class="isChichek ? 'pointer' : ''"
        v-for="(item, index) in list"
        :key="index"
        @click="selectAddress(item)"
        v-show="allShow ? true : index < 8"
      >
        <div class="edit">
          <span @click="goEdit(item)">{{$t('address.edit')}}</span>
          <span @click="deletAddress(item, index)">{{$t('address.delet')}}</span>
        </div>
        <div class="info">
          <div>
            <span>{{$t('address.name')}}</span>
            <p>{{ item.name }}</p>
          </div>
          <div>
            <span>{{$t('address.city')}}</span>
            <p>
              {{ item.province }}{{ item.city }}{{ item.area
              }}{{ item.address }}
            </p>
          </div>
          <div>
            <span>{{$t('address.contact')}}</span>
            <p>+86 {{ item.tel }}</p>
          </div>
          <div>
            <span>{{$t('address.post')}}</span>
            <p>{{ item.zcode }}</p>
          </div>
          <div>
            <span>{{$t('address.email_address')}}</span>
            <p>{{ item.email }}</p>
          </div>
        </div>
        <div class="status" v-if="item.defaults">
          <span>{{ isChichek ? $t('address.select_address') : $t('address.default_address') }}</span>
        </div>
      </div>
    </div>
    <div
      class="look-all"
      v-if="list.length > 8"
      @click="allShow = !allShow"
    >{{ allShow ? $t('address.hide_all') : $t('address.show_all') }}</div>
    <m-empty v-if="!list.length" :title="$t('address.add_tip')" :btnShow="false"></m-empty>
    <div class="addbtn">
      <q-btn class="full-width" color="primary" :label="$t('address.new_address')" @click="goEdit()" />
    </div>
  </div>
</template>

<script>
import mEmpty from '@/components/Empty.vue'
import { address } from '@/api/my'
export default {
  components: {
    mEmpty
  },
  data () {
    return {
      list: [],
      isChichek: false,
      allShow: false
    }
  },
  methods: {
    goEdit (info) {
      if (info === undefined) {
        this.$router.push('/my/addressEdit')
      } else {
        this.$router.push('/my/addressEdit?info=' + JSON.stringify(info))
      }
    },
    async getAddress () {
      const res = await address({
        type: 0
      })
      this.list = res.list
    },
    async selectAddress (item) {
      if (!this.isChichek) {
        return
      }
      const obj = JSON.parse(JSON.stringify(item))
      obj.defaults = true
      const res = await address({
        type: 2,
        ...obj
      })
      if (res.status === 1) {
        this.list.forEach(i => {
          i.defaults = false
        })
        item.defaults = true
      }
    },
    deletAddress (item, index) {
      this.$q
        .dialog({
          message: this.$t('common.confirm_delete'),
          cancel: true
        })
        .onOk(async () => {
          const res = await address({
            type: 4,
            guid: item.guid
          })
          if (res.status === 1) {
            this.list.splice(index, 1)
          }
        })
    }
  },
  mounted () {
    this.getAddress()
    if (this.$route.query.url === 'chichek') {
      this.isChichek = true
    }
  }
}
</script>

<style lang="scss" scoped>
.back {
  max-width: 1500px;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: -55px;
  cursor: pointer;
  i {
    margin-right: 5px;
  }
  span {
    font-size: 14px;
  }
}
h5 {
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  margin: 30px auto;
}
.addresslist {
  max-width: 870px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .adItem {
    width: 49%;
    min-height: 120px;
    border: 1px solid #a8a8a8;
    border-radius: 5px;
    margin-bottom: 20px;
    position: relative;
    overflow: hidden;
    transition: all 0.4s;
    padding-bottom: 30px;
    &.pointer {
      cursor: pointer;
    }
    &:hover {
      border-color: #000;
    }
    .edit {
      width: 100%;
      height: 35px;
      text-align: right;
      font-size: 14px;
      color: #a8a8a8;
      line-height: 30px;
      span {
        margin-right: 15px;
        cursor: pointer;
        &:hover {
          color: #000;
        }
      }
    }
    .info {
      padding: 0 26px;
      font-size: 14px;
      flex-wrap: wrap;
      div {
        display: flex;
        &:not(:last-child) {
          margin-bottom: 10px;
        }
        span {
          display: inline-block;
          min-width: 70px;
          flex-shrink: 0;
          color: #a8a8a8;
        }
        p {
          margin-bottom: 0;
        }
      }
    }
    .status {
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: right;
      span {
        display: inline-block;
        // width: 135px;
        height: 28px;
        line-height: 28px;
        background-color: #000;
        color: #fff;
        text-align: center;
        padding: 0 10px;
      }
    }
  }
}
.look-all {
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
  margin: 10px 0;
}
.addbtn {
  width: 300px;
  margin: 20px auto;
}
</style>
